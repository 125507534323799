import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import ContractMobilePage from "./Mobile/ContractMobile";
import ContractDesktopPage from "./Desktop/ContractDesktop";

const ContractPage = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <ContractMobilePage />;
  return <ContractDesktopPage />;
});

export default ContractPage;
