import React, { useState } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useNotify } from "_common/component/NotifyProvider";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import iconList from "assets/icons/icon_list.svg";
import ListCoin from "./ListCoin";

const listTime = [
  {
    value: "line",
    text: "line",
  },
  {
    value: "1m",
    text: "1m",
  },
  {
    value: "5m",
    text: "5m",
  },
  {
    value: "15m",
    text: "15m",
  },
  {
    value: "30m",
    text: "30m",
  },
  {
    value: "1h",
    text: "1h",
  },
  {
    value: "4h",
    text: "4h",
  },
  {
    value: "1d",
    text: "1D",
  },
];

export default function GameHeader({
  game_id,
  data_price,
  isLight,
  setIsLight,
  time,
}) {
  const navigate = useNavigate();
  const { dataGame } = useNotify();
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="trade-header">
      <div className="action-group">
        <div className="coin" onClick={() => setOpenModal(true)}>
          <img
            src={iconList}
            alt=""
            width="25"
            height="25"
            className="mt-[6px]"
          />
          <span>
            <b className="text-uppercase">{game_id?.split("usdt")?.[0]}</b>
            <b className="text-uppercase">/USDT</b>
          </span>
        </div>
      </div>
      <div className="price-info">
        <div className="col1">
          <h4
            className={classNames({
              "text-red": Number(data_price?.priceChangePercent) <= 0,
              "text-green": Number(data_price?.priceChangePercent) > 0,
            })}
          >
            {formatNumber(dataGame?.lastPrice, "0,0.[0000]")}
          </h4>
          <h5
            className={classNames({
              "text-red": Number(data_price?.priceChangePercent) <= 0,
              "text-green": Number(data_price?.priceChangePercent) > 0,
            })}
          >
            <span>{formatNumber(data_price?.priceChange, "0,0.[000]")}</span>
            <span className="ml-2">
              {formatNumber(data_price?.priceChangePercent, "0,0.[00]")}%
            </span>
          </h5>
        </div>
        <div className="col2">
          <div className="!w-full flex">
            <p className="col1">{i18next.t("shortest")}</p>
            <h5 className="col2 justify-end">
              {formatNumber(data_price?.lowPrice, "0,0.[00000]")}
            </h5>
          </div>
          <div className="!w-full flex">
            <p className="col1">{i18next.t("tallest")}</p>
            <h5 className="col2 justify-end">
              {formatNumber(data_price?.highPrice, "0,0.[00000]")}
            </h5>
          </div>
          <div className="!w-full flex">
            <p className="col1">24h Số lượng</p>
            <h5 className="col2 justify-end">
              {formatNumber(data_price?.volume, "0,0.[0000]")}
            </h5>
          </div>
        </div>
      </div>
      <div className="chart-time">
        {listTime?.map((item) => (
          <button
            type="button"
            key={item.value}
            className={classNames("btn btn-time", {
              active: time === item?.value,
            })}
            onClick={() =>
              (window.location.href = `${window.location.pathname}?time=${
                item?.value
              }&symbol=${game_id}&theme=${isLight ? "light" : "dark"}`)
            }
          >
            {item.text}
          </button>
        ))}
      </div>
      <ListCoin open={openModal} onClose={() => setOpenModal(false)} />
    </div>
  );
}
