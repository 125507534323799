export const paymentStatusText = {
  pending: "Đang xử lý",
  complete: "Hoàn thành",
  error: "Thất bại",
  reject: "Thất bại",
};

export const paymentTypeText = {
  withdraw: "Withdraw",
  deposit: "Deposit",
  refund: "Refund",
};

export const paymentTypeColor = {
  withdraw: "text-red-600",
  deposit: "text-yellow-700",
  refund: "text-yellow-700",
  system: "text-yellow-700",
  commission: "text-yellow-700",
  add_money: "text-yellow-700",
  sub_money: "text-red-600",
};

export const paymentMethodText = {
  vnd: "TRC20",
  game: "ERC20",
};

export const paymentStatusColor = {
  pending: "text-yellow-700",
  complete: "text-green-600",
  error: "text-red-600",
};

export const paymentAddType = {
  exchange: "-",
  withdraw: "-",
  deposit: "+",
  add_money: "+",
  sub_money: "-",
  commission: "+",
  bonus: "+",
};

export const gameStatusText = {
  betGame: "Đang cược",
  win: "Đã trúng thưởng",
  loss: "Chưa trúng thưởng",
};

export const gameTypeText = {
  bet1: "Quần áo",
  bet2: "Mũ",
  bet3: "Trang sức",
  bet4: "Đồng hồ",
  bet5: "Giày dép",
};

export const gameBetItemType = {
  buy: "buy",
  sell: "sell",
};

export const gameLevelText = {
  level_1: "DIOR",
  level_2: "GUCCI",
  level_3: "CHANEL",
};

export const positionKeyText = {
  agency: "Đại lý",
  user: "Thành viên",
  admin: "Quản trị viên",
  super: "Quản trị viên",
};

export const fundStatus = {
  pending: "amount_pending",
  finish: "complete",
  refund: "refund",
  error: "reject",
};

export const gameTypeList = [
  {
    time: 30,
    profit: 30,
    text: "30 sec",
  },
  {
    time: 60,
    profit: 50,
    text: "60 sec",
  },
  {
    time: 90,
    profit: 70,
    text: "90 sec",
  },
  {
    time: 120,
    profit: 90,
    text: "120 sec",
  },
];
