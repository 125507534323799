import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import { formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";

const MarketPrice = observer(() => {
  const navigate = useNavigate();

  const {
    authStore: { banners, top_coin, games, authenticated },
  } = useStores();

  const { data, refetch } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 30,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const coinListPrice = useMemo(
    () =>
      games.map((item) => {
        const price = data?.find(
          (obj) => obj?.symbol?.toLowerCase() === item?.code
        );

        return { ...item, price };
      }),
    [games, data]
  );

  return (
    <div className="css-1hc8c4h">
      <div className="css-dlistbox">
        <div className="css-dlbbox">
          <div className="listtitle tcc fl col-2">
            <span className="f12 fch">Tên</span>
          </div>
          <div className="listtitle tcc fl col-2">
            <span className="f12 fch">Giá bán</span>
          </div>
          <div className="listtitle tcc fl col-2">
            <span className="f12 fch">Trích dẫn thay đổi</span>
          </div>
          <div className="listtitle tcc fl col-2">
            <span className="f12 fch">Cao nhất/Thấp nhất</span>
          </div>
          <div className="listtitle tcc fl col-2">
            <span className="f12 fch">24Hsố lượng</span>
          </div>
          <div className="listtitle tcc fl col-2">
            <span className="f12 fch">Vận hành</span>
          </div>
        </div>
        <div className="table-scroll">
          {coinListPrice?.map((item) => (
            <div className="css-dlbbox2 market-div" key={item?.code}>
              <div className="listtitle2 name-coin tcl fl col-2">
                <img src={item?.image_url} alt="" className="cion_logo" />
                <span className="f14 fch fw cn_btc">{item?.name}</span>
              </div>

              <div className="listtitle2 tcc fl col-2">
                <span className="f14 fch cpr_btc">
                  <span
                    className={classNames("f14  fw", {
                      "fgreen ": item?.price?.priceChangePercent > 0,
                      fred: item?.price?.priceChangePercent <= 0,
                    })}
                  >
                    {formatNumber(item?.price?.lastPrice, "0,0.[00000]")}
                  </span>
                </span>
              </div>

              <div className="listtitle2 tcc fl col-2 cch_btc">
                <span
                  className={classNames("f14   fw", {
                    "profit_loss_g fgreen ":
                      item?.price?.priceChangePercent > 0,
                    "profit_loss_r fred": item?.price?.priceChangePercent <= 0,
                  })}
                >
                  {formatNumber(item?.price?.priceChangePercent, "0,0.[00]")}%
                </span>
              </div>

              <div className="listtitle2 tcc fl col-2">
                <span className="f14 fch fw hl_btc">
                  {formatNumber(item?.price?.highPrice, "0,0.[00000]")} /{" "}
                  {formatNumber(item?.price?.lowPrice, "0,0.[00000]")}
                </span>
              </div>
              <div className="listtitle2 tcc fl col-2">
                <span className="f14 fch fw vol_btc">
                  {formatNumber(item?.price?.volume, "0,0.[00000]")}
                </span>
              </div>
              <div className="listtitle2 tcc fl col-2">
                <div className="tcc option-box">
                  <Link
                    to={`/trade?symbol=${item?.code}`}
                    className="f14 "
                    style={{ color: "#000", textDecoration: "none" }}
                  >
                    Giao dịch
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default MarketPrice;
