import React from "react";
import icon_noMessage from "assets/img/empty-data.png";

export default function NoData({ description = "No data" }) {
  return (
    <div className="noMSG_out nodata">
      <div className="noMSG">
        <img alt="kubet" src={icon_noMessage} />
        <div>{description}</div>
      </div>
    </div>
  );
}
