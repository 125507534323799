import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useStores } from "_common/hooks";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { formatBalance } from "_common/utils/formatValue";
import icon1 from "assets/img/icon/chongzhi.png";
import icon2 from "assets/img/icon/tixin.png";
import icon3 from "assets/img/icon/buy.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import {
  faClock,
  faCoins,
  faExchange,
  faFileExport,
  faHeadphones,
  faRandom,
  faRotate,
  faUser,
  faWallet,
  faYenSign,
} from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";

const General = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore: { display_balance, user, tokens },
  } = useStores();

  useEffect(() => {
    queryClient.invalidateQueries(["get_profile"]);
  }, []);

  return (
    <div id="general">
      <div className="mu-card-header">
        <div className="mu-avatar">
          <FontAwesomeIcon icon={faUser} />
        </div>
        <div className="mu-card-header-title">
          <div className="mu-card-title">{user?.id}</div>
          <div className="mu-card-sub-title">Mã mời: {user?.ref_no}</div>
        </div>
      </div>
      <div className="flex flex-col w-full gap-10 ">
        <div className="flex flex-1">
          <div className="flex flex-col w-full gap-4">
            <div className="allmoneybox">
              <p className="py-[10px] px-[10px] text-[#78C9EB]">
                Trung tâm tài sản (USD)
              </p>
              <div className="allmbox_3">
                <span className="text-[24px] text-[#78C9EB] mr-[5px]">≈</span>
                <span className="zhehebox font-bold text-[30px] text-[#78C9EB]">
                  {formatBalance(user?.balance, display_balance)}
                </span>
              </div>
              <div className="allmbox_btn">
                <div className="allmbox_btn_1">
                  <Link to="/member/recharge">
                    <div className="allmbox_cbtn ">
                      <FontAwesomeIcon icon={faWallet} />
                      <span className="fe6im">Nạp tiền</span>
                    </div>
                  </Link>
                  <Link to="/member/withdraw">
                    <div className="allmbox_tbtn ">
                      <FontAwesomeIcon icon={faYenSign} />
                      <span className="fe6im">Rút</span>
                    </div>
                  </Link>
                  <Link to="/member">
                    <div className="allmbox_tbtn ">
                      <FontAwesomeIcon icon={faExchange} />
                      <span className="fe6im">Chuyển khoản</span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="px-[16px]">
              <Link to="/member/wallet/spot">
                <div className="lbox lbox-j !bg-white">
                  <div className="lbox_l flex items-center">
                    <FontAwesomeIcon
                      icon={faRandom}
                      width="18"
                      fontSize="18"
                      color="#78C9EB"
                    />
                    <span className="ml-[10px] f16 ">Tài khoản giao ngay</span>
                  </div>
                </div>
              </Link>
              <Link to="/member/wallet/contract">
                <div className="lbox lbox-j !bg-white">
                  <div className="lbox_l flex items-center">
                    <FontAwesomeIcon
                      icon={faFileExport}
                      width="18"
                      fontSize="18"
                      color="#78C9EB"
                    />
                    <span className="ml-[10px] f16 ">Tài khoản hợp đồng</span>
                  </div>
                </div>
              </Link>
              <Link to="/member/wallet/trading">
                <div className="lbox lbox-j !bg-white">
                  <div className="lbox_l flex items-center !w-[100%]">
                    <FontAwesomeIcon
                      icon={faClock}
                      width="18"
                      fontSize="18"
                      color="#78C9EB"
                    />
                    <span className="ml-[10px] f16 ">
                      Tài khoản giao dịch quyền chọn
                    </span>
                  </div>
                </div>
              </Link>
              <Link to="/board/histories/balance">
                <div className="lbox lbox-j !bg-white">
                  <div className="lbox_l flex items-center">
                    <FontAwesomeIcon
                      icon={faCoins}
                      width="18"
                      fontSize="18"
                      color="#78C9EB"
                    />
                    <span className="ml-[10px] f16 ">Bản ghi rút tiền</span>
                  </div>
                </div>
              </Link>
              <Link to="/cskh">
                <div className="lbox lbox-j !bg-white">
                  <div className="lbox_l flex items-center">
                    <FontAwesomeIcon
                      icon={faHeadphones}
                      width="18"
                      fontSize="18"
                      color="#78C9EB"
                    />
                    <span className="ml-[10px] f16 ">Khách hàng</span>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default General;
