import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faClose } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { useStores } from "_common/hooks";
import Loading from "_common/component/Loading";
import classNames from "classnames";
import i18next from "i18next";
import ActiveCode from "./ActiveCode";

const schema = yup
  .object({
    username: yup.string().required(i18next.t("verify_require_username")),
    password: yup.string().required(i18next.t("verify_require_password")),
    confirmed: yup
      .string()
      .required(i18next.t("verify_require_confirm_password"))
      .oneOf([yup.ref("password")], i18next.t("verify_unmatch_password")),
  })
  .required();

function RegisterMobile() {
  const navigate = useNavigate();
  const { authStore } = useStores();
  const [params] = useSearchParams();

  const ref_no = params?.get("ref_no");
  const type = params?.get("type") || "email";

  const [isSuccess, setIsSuccess] = useState(false);

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.register(variables),
    {
      onSuccess: (res) => {
        // authStore.updateToken(res?.data);
        // authStore.updateUser(res?.data);
        // setTimeout(() => {
        //   navigate("/");
        // }, 200);
        setIsSuccess(true);
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleRegister = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  useEffect(() => {
    if (!ref_no) return;
    setValue("ref_no", ref_no);
  }, [ref_no]);

  if (isSuccess) return <ActiveCode />;

  return (
    <>
      <div className="no_headers header_box">
        <div
          className="fr bhalf allhg txtr"
          style={{ lineHeight: "75px", paddingRight: "1.5rem" }}
        >
          <FontAwesomeIcon
            icon={faClose}
            style={{ fontSize: "30px", color: "rgb(109, 109, 109)" }}
            onClick={() => navigate("/auth/login")}
          />
        </div>
        <div
          className="fr bhalf allhg txtr"
          style={{ lineHeight: "50px", paddingRight: "1rem" }}
        >
          <svg
            className="lang-svg inline-block"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="26"
          >
            <path
              d="M782 912H242c-71.68 0-130-58.32-130-130V242c0-71.68 58.32-130 130-130h540c71.68 0 130 58.32 130 130v540c0 71.68-58.32 130-130 130zM242 172c-38.59 0-70 31.41-70 70v540c0 38.59 31.41 70 70 70h540c38.59 0 70-31.41 70-70V242c0-38.59-31.41-70-70-70H242z"
              fill="#ffffff"
            />
            <path
              d="M455.49 621.78c-3.97-1.08-8.51-1.71-13.51-1.9-4.32-0.15-8.84-0.21-13.6-0.21h-100.5v-86.64h107.87c9.01 0 15.72-1.65 20.48-4.99 4.09-2.86 8.98-8.65 8.98-20.14 0-9.2-2.82-16.12-8.37-20.56-4.91-3.93-11.8-5.86-21.09-5.86H327.89V404.8h112.2c8.39 0 14.92-1.69 19.96-5.14 4.34-2.94 9.51-8.88 9.5-20.98-0.48-9.18-3.66-15.97-9.53-20.18-4.96-3.53-11.48-5.25-19.93-5.25H302.76c-10.47 0-18.01 2.31-23.07 7.09-5.17 4.93-7.69 12.25-7.69 22.36v259.06c0 11.11 3.15 19.06 9.36 23.65 5.28 3.91 12.28 5.82 21.4 5.82h139.93c9.38 0 16.37-1.54 21.44-4.74 4.45-2.83 9.75-8.84 9.75-21.26 0-8.19-3.11-13.24-5.7-16.01-3.26-3.53-7.52-6.03-12.69-7.44zM745.3 356.36c-4.74-5.25-11.96-7.91-21.46-7.91-9.85 0-17.18 3.02-21.79 8.99-3.95 5.1-5.94 11.4-5.94 18.74v188.1L559.8 364.97c-2.85-3.45-5.68-6.6-8.5-9.41-3.24-3.24-9.06-7.11-18.51-7.11-11.87 0-17.89 5.16-20.85 9.5-3.42 4.99-5.14 11.13-5.14 18.23v271.21c0 7.47 2.22 13.94 6.59 19.23 3.36 4.06 9.75 8.93 21.13 8.93 11.23 0 17.72-4.74 21.17-8.72 4.64-5.33 6.99-11.89 6.99-19.44v-184.6l135.35 195.08c3.18 4.61 6.73 8.5 10.59 11.63 5.01 4.02 10.86 6.05 17.39 6.05 11.9 0 17.92-5.18 20.85-9.5 3.42-4.99 5.14-11.13 5.14-18.23V376.19c0-8.19-2.24-14.85-6.7-19.83z"
              fill="#ffffff"
            />
          </svg>
        </div>
      </div>
      <form id="auth-sign-up" onSubmit={handleSubmit(handleRegister)}>
        {isLoading && <Loading />}
        <div className="sbui-space-col sbui-space-y-6 pr-[1rem] pl-[1rem]">
          <div className="fd-box">
            <span
              className={classNames("fsr dfs", {
                fsl: type === "email",
              })}
              onClick={() => navigate("/auth/register?type=email")}
            >
              Email
            </span>
            <span
              className={classNames("fsr dfs", {
                fsl: type === "phone",
              })}
              onClick={() => navigate("/auth/register?type=phone")}
            >
              {i18next.t("Số điện thoại")}
            </span>
          </div>
          <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive !mt-[0]">
            <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
              <div className="sbui-formlayout__label">
                {type === "email"
                  ? "Vui lòng nhập Email"
                  : "Vui lòng nhập số điện thoại"}
              </div>
            </div>
            <div className="sbui-formlayout__content-container-horizontal">
              <div className="sbui-input-container">
                <input
                  type="text"
                  className="sbui-input"
                  {...register("username")}
                />
              </div>
            </div>
          </div>
          <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
            <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
              <div className="sbui-formlayout__label">
                Vui lòng nhập mật khẩu
              </div>
            </div>
            <div className="sbui-formlayout__content-container-horizontal">
              <div className="sbui-input-container">
                <input
                  autoComplete="current-password"
                  type="password"
                  className="sbui-input"
                  {...register("password")}
                />
              </div>
            </div>
          </div>
          <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
            <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
              <div className="sbui-formlayout__label">
                Vui lòng nhập lại mật khẩu
              </div>
            </div>
            <div className="sbui-formlayout__content-container-horizontal">
              <div className="sbui-input-container">
                <input
                  autoComplete="current-password"
                  type="password"
                  className="sbui-input"
                  {...register("confirmed")}
                />
              </div>
            </div>
          </div>
          {Number(authStore.general?.allow_ref_no) === 1 && (
            <div>
              <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                  <div className="sbui-formlayout__label">
                    Vui lòng nhập mã mời
                  </div>
                </div>
                <div className="sbui-formlayout__content-container-horizontal">
                  <div className="sbui-input-container">
                    <input
                      disabled={!!ref_no}
                      type="text"
                      className="sbui-input"
                      {...register("ref_no")}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="sbui-space-col sbui-space-y-6">
            <span className="sbui-btn-container sbui-btn--w-full">
              <button
                className="text-[#ffffff] font-[600] !bg-[#78c9eb] w-full min-h-[44px]"
                type="submit"
                disabled={!isValid || isLoading}
              >
                <span>{i18next.t("register")}</span>
              </button>
            </span>
            <div className="sbui-space-row flex justify-between sbui-space-x-2 pt-3 ">
              <Link to="/auth/login">
                {i18next.t("Bạn đã có tài khoản? Đăng nhập ngay")}
              </Link>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default RegisterMobile;
