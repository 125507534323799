import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { PaymentApi } from "states/api/payment";
import { useForm } from "react-hook-form";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import i18next from "i18next";

const schema = yup
  .object({
    address: yup.string().required(i18next.t("verify_require")),
    amount: yup.number().required(i18next.t("verify_require")),
  })
  .required();

const WithdrawPage = observer(() => {
  const {
    authStore: { user, display_balance, general, coins },
  } = useStores();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const [params] = useSearchParams();

  const symbol = params.get("symbol");
  const network = params.get("network");

  const [address, setAddress] = useState();
  const [payment_images, setPaymentImage] = useState();

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const mainCoin = useMemo(
    () => coins?.find((obj) => obj?.symbol === symbol),
    [coins, symbol]
  );

  useEffect(() => {
    if (!mainCoin) return;
    if (symbol === "usdt") {
      if (network === "trc20") {
        setAddress(mainCoin?.trc20_address);
      } else if (network === "erc20") {
        setAddress(mainCoin?.erc20_address);
      } else {
        setAddress(mainCoin?.primary_address);
      }
    } else {
      setAddress(mainCoin?.primary_address);
    }
  }, [mainCoin, symbol, network]);

  const { mutate: onSubmit, isLoading } = useMutation(
    (variables) =>
      PaymentApi.withdraw({
        params: {
          ...variables,
          symbol,
          type: "withdraw",
        },
      }),
    {
      onSuccess: (res) => {
        reset();
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["payment", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("withdrawal_successful")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (isLoading) return null;
    const amount = values?.amount;

    if (amount < Number(general?.min_withdraw || 0))
      return NotificationManager.error(
        `${i18next.t("enter_large_amount_msg")} ${formatNumber(
          general?.min_withdraw
        )}`
      );
    if (amount > Number(general?.max_withdraw || 0))
      return NotificationManager.error(
        `${i18next.t("enter_smaller_amount_msg")} ${formatNumber(
          general?.max_withdraw
        )}`
      );
    onSubmit({ ...values });
    return null;
  };

  return (
    <main className="css-1wr4jig">
      <main className="css-xry4yv !flex-row">
        <div className="css-1ay57iv">
          <div className="css-8hzjvg">
            <div className="css-1eklhqk">
              <div className="css-190uhut">
                <div className="css-11y6cix" onClick={() => navigate(-1)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="css-146agw4"
                  >
                    <path
                      d="M16.414 18.586L15 20l-8-8 8-8 1.414 1.414L9.828 12l6.586 6.586z"
                      fill="#76808F"
                    />
                  </svg>
                  <div className="css-1djsyd6">Rút tiền kỹ thuật số</div>
                </div>
              </div>
            </div>
          </div>
          <div className="css-1dihobw">
            <div className="css-1eklhqk">
              <div className="css-tu2ioc w-[60%] float-left">
                <form className="css-1ekpz1z" onSubmit={handleSubmit(onSave)}>
                  <div className="czbox">
                    <div className="czbox_1">
                      <span className="fch f16">Chọn tiền tệ</span>
                    </div>
                    <div className="czbox_2">
                      <div className="czbox_3">
                        <span className="fch f12">Cặp tiền</span>
                      </div>
                      <div className="czbox_4">
                        <span className="fch f14">USDT</span>
                      </div>
                    </div>
                  </div>
                  <div className="czbox">
                    <div className="czbox_1">
                      <span className="fch f16">Mạng nạp lại</span>
                    </div>
                    <div className="czbox_2">
                      <div className="czbox_3">
                        <span className="fch f12">Mạng</span>
                      </div>
                      <div className="czbox_4">
                        <span className="fch f14">TRC20</span>
                      </div>
                    </div>
                  </div>
                  <div className="czbox">
                    <div className="czbox_1">
                      <span className="fch f16">Số tài khoản</span>
                    </div>
                    <div className="czbox_2">
                      <div className="czbox_3">
                        <span className="fch f12">Số tài khoản</span>
                      </div>
                      <div className="czbox_6">
                        <input
                          type="text"
                          name="address"
                          className="czbox_5 !w-full !h-full !border-[#f5f5f5] !p-[0] !pl-[15px]"
                          {...register("address")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="czbox">
                    <div className="czbox_1">
                      <span className="fch f16">Số tiền rút</span>
                    </div>
                    <div className="czbox_2">
                      <div className="czbox_3">
                        <span className="fch f12">Số lượng</span>
                        <span className="f12 fcy">
                          &nbsp;Có sẵn&nbsp;&nbsp;
                        </span>
                        <span className="f12 fcy">
                          {formatBalance(user?.balance, display_balance)}
                        </span>
                        <span className="f12 fcy">&nbsp;&nbsp;USDT</span>
                      </div>
                      <div className="czbox_6">
                        <input
                          type="number"
                          inputMode="decimal"
                          min={0}
                          max={1000000000000}
                          name="address"
                          autoComplete="off"
                          className="czbox_5 !w-full !h-full !border-[#f5f5f5] !p-[0] !pl-[15px]"
                          {...register("amount")}
                        />
                        <span className="f12 fcy">
                          &nbsp;Tài khoản thực tế&nbsp;&nbsp;
                        </span>
                        <span className="f12 fcy">
                          {formatBalance(user?.balance, display_balance)}
                        </span>
                        <span className="f12 fcy">&nbsp;&nbsp;USDT</span>
                      </div>
                    </div>
                  </div>
                  <div className="czbox">
                    <div className="czbox_2">
                      <div className="czbox_3" />
                      <button
                        type="submit"
                        disabled={!isValid}
                        className="czbox_4 text-center"
                        style={{
                          background:
                            "linear-gradient(to left,#eeb80d,#ffe35b)",
                        }}
                      >
                        <span className="fch f14">Gửi đi</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="w-[35%] h-[600px] p-[10px] float-right">
                <div className="w-full h-[60px] leading-[60px]">
                  <span>Lời khuyên tử tế</span>
                </div>
                <div className="w-full min-h-[180px] p-[10px]">
                  <span className="f14 text-[#ff0000]">
                    Số tiền tối thiểu：10.00USDT，Số tiền tối thiểu ít hơn sẽ
                    không nhận được tài khoản và không thể được trả lại
                  </span>
                  <br />
                  <br />
                  <span className="f12 fch">
                    Để đảm bảo bảo mật tiền, khi chiến lược bảo mật tài khoản
                    của bạn được thay đổi và mật khẩu được sửa đổi, chúng tôi sẽ
                    tiến hành xem xét thủ công hóa đơn thanh toán. Vui lòng kiên
                    nhẫn chờ đợi điện thoại hoặc email của nhân viên.
                  </span>
                  <br />
                  <br />
                  <span className="f14 fch">
                    Vui lòng xác nhận bảo mật của máy tính và trình duyệt để
                    ngăn thông tin bị giả mạo hoặc bị rò rỉ
                  </span>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </main>
  );
});

export default WithdrawPage;
