import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { GamePlayerApi } from "states/api/gamePlayer";
import { formatNumber } from "_common/utils/formatValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faLongArrowAltLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useNotify } from "_common/component/NotifyProvider";
import NoData from "_common/component/NoData";

export default function HistoryTrade({ status }) {
  const navigate = useNavigate();
  const [item, setItem] = useState();
  const { dataGame } = useNotify();

  const { data, refetch } = useQuery(
    ["game-player", "list", status],
    () =>
      GamePlayerApi.getList({
        params: {
          page: 1,
          limit: 50,
          status,
          type: "spot",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const renderItemDetail = () => (
    <>
      <div className="no_header relative">
        <span
          className="btn_back absolute left-[10px] translate-y-[50%]"
          onClick={() => setItem()}
        >
          <FontAwesomeIcon icon={faLongArrowAltLeft} color="#707A8A" />
        </span>
        <div className="infotbox text-center">
          <b className="text-[24px] text-[#00b897] text-uppercase">
            {item?.game?.name} Chi tiết hợp đồng
          </b>
        </div>
      </div>
      <div className="infobox">
        <div className="lbox">
          <div className="lbox_l">
            <span className="fe6im fzmm">Giá trị hợp đồng</span>
          </div>
          <div className="lbox_r">
            <span className="fe6im fzmmm">
              {formatNumber(item?.amount)} USDT
            </span>
          </div>
        </div>
        <div className="lbox">
          <div className="lbox_l">
            <span className="fe6im fzmm">Thời hạn hợp đồng</span>
          </div>
          <div className="lbox_r">
            <span className="fe6im fzmmm">{item?.type}s</span>
          </div>
        </div>
        <div className="lbox">
          <div className="lbox_l">
            <span className="fe6im fzmm">Loại hợp đồng</span>
          </div>
          <div className="lbox_r">
            <span
              className={`fzmmm text-[#0ecb81] ${
                item?.bet_game === "sell" && "text-[#f5465c]"
              }`}
            >
              {i18next.t(item?.bet_game)}
            </span>
          </div>
        </div>
        <div className="lbox">
          <div className="lbox_l">
            <span className="fe6im fzmm">Giá mở cửa</span>
          </div>
          <div className="lbox_r">
            <span className="fe6im fzmm">
              {formatNumber(item?.open, "0,0.0000")}
            </span>
          </div>
        </div>
        <div className="lbox">
          <div className="lbox_l">
            <span className="fe6im fzmm">Thiết lập thời gian</span>
          </div>
          <div className="lbox_r">
            <span className="fe6im fzmm">
              {moment(item?.created_at).format("YYYY-MM-DD HH:mm:ss")}
            </span>
          </div>
        </div>
        <div className="lbox">
          <div className="lbox_l">
            <span className="fe6im fzmm">Giá đóng cửa</span>
          </div>
          <div className="lbox_r">
            <span className="fe6im fzmm">
              {" "}
              {formatNumber(item?.close, "0,0.0000")}
            </span>
          </div>
        </div>
        <div className="lbox">
          <div className="lbox_l">
            <span className="fe6im fzmm">Thời gian định vị</span>
          </div>
          <div className="lbox_r">
            <span className="fe6im fzmm">
              {moment(item?.created_at).format("YYYY-MM-DD HH:mm:ss")}
            </span>
          </div>
        </div>
        <div className="lbox">
          <div className="lbox_l">
            <span className="fe6im fzmm">Lợi nhuận và thua lỗ</span>
          </div>
          <div className="lbox_r">
            {item?.status === "lose" && (
              <span className="text-[#f5465c]">
                -{formatNumber(item?.amount_lose, "0,0")} USDT
              </span>
            )}
            {item?.status === "win" && (
              <span className="text-[#00b919]">
                +{formatNumber(item?.amount_payback, "0,0")} USDT
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );

  const gameList = get(data, "data", []) || [];

  return item ? (
    renderItemDetail()
  ) : (
    <div className="mx-auto w-full">
      <div className="list-history-data ">
        {!gameList?.length ? (
          <NoData description={i18next.t("no_data")} />
        ) : (
          gameList?.map((item) => {
            const time = Number(get(dataGame?.[item?.id], "time", 0));
            const type = Number(get(item, "type", 360));
            const process = (time / type) * 100;
            return (
              <div key={item.id} className="listbox">
                <div className="listbox_title">
                  <div className="listbox_title_l">
                    <span
                      className={`f14 text-[16px] text-[500] text-[#0ecb81] ${
                        item?.bet_game === "sell" && "text-[#f5465c]"
                      }`}
                    >
                      {i18next.t(item?.bet_game)}:
                    </span>
                    <span className="fcc f14 fe6im text-[16px] text-[500] text-uppercase ml-[5px]">
                      {item?.game?.name}
                    </span>
                  </div>
                  <div onClick={() => setItem(item)}>
                    <div className="listbox_title_r">
                      <span className="fcc text-[12px] text-[#565a61]">
                        {item?.status === "bet"
                          ? "Chờ kết quả"
                          : "Đã thanh toán"}
                      </span>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        color="#e6e6e6"
                        className="ml-[5px]"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full h-[60px]">
                  <div className="w-[33%] h-[60px] inline-block">
                    <div className="w-full h-[30px] leading-[40px]">
                      <span className="f12 text-[#151d25]">Số tiền đầu tư</span>
                    </div>
                    <div className="w-full h-[30px] leading-[30px]">
                      <span className="f12 text-[#565a61]">
                        {formatNumber(item?.amount)}
                      </span>
                    </div>
                  </div>
                  <div className="w-[33%] h-[60px] inline-block">
                    <div className="w-full h-[30px] leading-[40px]">
                      <span className="f12 text-[#151d25]">Giá mở cửa</span>
                    </div>
                    <div className="w-full h-[30px] leading-[30px]">
                      <span className="f12 text-[#565a61]">
                        {formatNumber(item?.open, "0,0.0000")}
                      </span>
                    </div>
                  </div>
                  <div className="w-[33%] h-[60px] inline-block">
                    <div className="w-full h-[30px] leading-[40px] text-right">
                      <span className="f12 text-[#151d25]">
                        Thời gian đóng cửa
                      </span>
                    </div>
                    <div className="w-full h-[30px] leading-[30px] text-right">
                      <span className="f12 text-[#565a61]">
                        {moment(item?.created_at).format("MM-DD HH:mm")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-full h-[60px]">
                  <div className="w-[33%] h-[60px] inline-block">
                    <div className="w-full h-[30px] leading-[40px]">
                      <span className="f12 text-[#151d25]">Kết quả</span>
                    </div>
                    <div className="w-full h-[30px] leading-[30px]">
                      <span className="f12 text-[#565a61]">
                        {item?.status === "bet" && "Đang tiến hành"}
                        {item?.status === "win" &&
                          `+${formatNumber(item?.amount_payback)}`}
                        {item?.status === "lose" &&
                          `-${formatNumber(item?.amount_lose)}`}
                      </span>
                    </div>
                  </div>
                  <div className="w-[33%] h-[60px] inline-block">
                    <div className="w-full h-[30px] leading-[40px]">
                      <span className="f12 text-[#151d25]">Giá đóng cửa</span>
                    </div>
                    <div className="w-full h-[30px] leading-[30px]">
                      <span className="f12 text-[#565a61]">
                        {item?.status === "bet"
                          ? "Đang tiến hành"
                          : formatNumber(item?.close, "0,0.0000")}
                      </span>
                    </div>
                  </div>
                  <div className="w-[33%] h-[60px] inline-block">
                    <div className="w-full h-[30px] leading-[40px] text-right">
                      <span className="f12 text-[#151d25]">
                        Thời gian đóng cửa
                      </span>
                    </div>
                    <div className="w-full h-[30px] leading-[30px] text-right">
                      <span className="f12 text-[#565a61]">
                        {item?.status === "bet"
                          ? "Đang tiến hành"
                          : moment(item?.updated_at).format("MM-DD HH:mm")}
                      </span>
                    </div>
                  </div>
                </div>
                {item?.status === "bet" && (
                  <div className="progress h-[10px] bg-[#1b1d29]">
                    <div
                      className="progress-bar"
                      style={{ width: `${process}%` }}
                    >
                      <span className="time">{dataGame?.[item?.id]?.time}</span>
                    </div>
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}
