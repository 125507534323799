import React, { useEffect } from "react";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { BankApi } from "states/api/bank";

export default function AddBankCard() {
  const navigate = useNavigate();
  const {
    authStore: { user },
  } = useStores();

  const { data, refetch } = useQuery(
    ["bank", "list"],
    () =>
      BankApi.getList({
        params: {
          page: 1,
          limit: 50,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="container !mt-[50px]">
      <HeaderTop title="Quản lý địa chỉ mẫu" />
      {data?.data?.map((item) => (
        <div className="addbox">
          <div className="address">
            <span className="fzmm fe6im">{item?.bank_name}</span>
          </div>
          <div className="address">
            <span className="fzmm fe6im">{item?.bank_owner}</span>
          </div>
          <div className="address">
            <span className="fzmm fe6im" id="address">
              {item?.bank_number_display}
            </span>
          </div>
        </div>
      ))}
      <div
        className="text-white absolute top-[15px] right-[5px] z-[999]"
        onClick={() => navigate("/member/bank/submit?bank_type=local")}
      >
        Thêm vào
      </div>
    </div>
  );
}
