import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNotify } from "_common/component/NotifyProvider";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import { get } from "lodash";
import { gameTypeList } from "_common/constants/statusType";
import ModalBet from "./ModalBet";

export default function GameBoard({ game_id, data_price }) {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, dataGame } = useNotify();
  const {
    authStore: { user, display_balance, loading, setLoading, general },
  } = useStores();
  const typeBet = [
    {
      type: "usdt",
      text: i18next.t("USDT"),
    },
  ];
  const [game_type, setGameType] = useState("buy");
  const [bet_type, setBetType] = useState(gameTypeList[0].time);
  const [amount, setAmount] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const gameProfit = useMemo(
    () => gameTypeList?.find((item) => item?.time === bet_type),
    [bet_type]
  );

  const handleChangeInput = (event) => {
    if (!event?.target?.value) {
      setAmount();
    } else {
      setAmount(Number(event?.target?.value));
    }
  };

  return (
    <div className="feature-board">
      <div className="group-action">
        <button
          type="button"
          className={classNames("btn btn-buy ", {
            active: game_type === "buy",
          })}
          onClick={() => setGameType("buy")}
        >
          {i18next.t("up")}
        </button>
        <button
          type="button"
          className={classNames("btn btn-sell ", {
            active: game_type === "sell",
          })}
          onClick={() => setGameType("sell")}
        >
          {i18next.t("fall")}
        </button>
      </div>
      <p>{i18next.t("transaction_mode")}</p>
      <select>
        {typeBet?.map((item) => (
          <option key={item?.type} value={item?.type}>
            {item?.text}
          </option>
        ))}
      </select>
      <p className="mt-[20px]">{i18next.t("opening_quantity")}</p>
      <div className="qty-input !w-full">
        <input
          placeholder={i18next.t("transaction_amount")}
          type="number"
          inputMode="decimal"
          className="sbui-input sbui-input--large"
          min={0}
          step="0.000001"
          value={amount}
          onChange={handleChangeInput}
        />
        <button
          type="button"
          className="btn btn-add"
          style={{
            width: 64,
          }}
        >
          USDT
        </button>
      </div>
      <p className=" mt-[20px]">{i18next.t("open_time")}</p>
      <select onChange={(e) => setBetType(Number(e?.target?.value))}>
        {gameTypeList?.map((item) => (
          <option
            key={item?.time}
            value={item?.time}
            onClick={() => setBetType(item.time)}
          >
            {item?.text}
          </option>
        ))}
      </select>
      <div className="info mt-[20px]">
        <span className="text-[#707A8A]">{i18next.t("profit_rate")}</span>
        <span className="text-[#707A8A]">
          {formatNumber(gameProfit?.profit, "0,0.[00000]")}%
        </span>
      </div>
      <div className="info mt-[5px]">
        <span className="text-[#707A8A]">{i18next.t("minimum_amount")}</span>
        <span className="text-[#707A8A]">
          {formatNumber(general?.min_bet, "0,0.[00000]")}
        </span>
      </div>
      <div className="info mt-[5px]">
        <span className="text-[#707A8A]">{i18next.t("usable")}</span>
        <span className="text-[#707A8A]">
          {formatNumber(user?.balance, "0,0.[00000]")}&nbsp;USD
        </span>
      </div>

      <div className="button-footer mt-[20px]">
        {game_type === "buy" && (
          <button
            type="button"
            className="btn btn-buy uppercase"
            onClick={() => setOpenModal(true)}
          >
            {i18next.t("up")} {game_id?.split("usdt")?.[0]}
          </button>
        )}
        {game_type === "sell" && (
          <button
            type="button"
            className="btn btn-sell uppercase"
            onClick={() => setOpenModal(true)}
          >
            {i18next.t("fall")} {game_id?.split("usdt")?.[0]}
          </button>
        )}
      </div>
      {openModal && (
        <ModalBet
          open={openModal}
          onClose={() => setOpenModal(false)}
          data={{
            game_id,
            type: bet_type,
            profit_rate: gameProfit?.profit,
            game_type,
            amount,
          }}
        />
      )}
    </div>
  );
}
