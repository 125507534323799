import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { FundApi } from "states/api/fund";
import logo from "assets/img/logo.png";
import { formatNumber } from "_common/utils/formatValue";
import { useNavigate } from "react-router-dom";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";

export default function ListData({ data = [] }) {
  const navigate = useNavigate();

  return (
    <div className="sbui-loading">
      <HeaderTop title={i18next.t("main_management_products")} />
      <div className="list-history-data">
        {data?.map((item) => (
          <div key={item?.id} className="item">
            <div className="py-[15px] px-[20px]">
              <div className="flex  justify-between gap-2">
                <div className="flex items-center gap-4">
                  <span className="text-lg font-bold">{item?.name}</span>
                </div>
              </div>
              <div className="tx_inputbox">
                <div className="tx_inputbox_l !w-[80%]">
                  <input
                    type="number"
                    inputMode="decimal"
                    min={0}
                    max={1000000000000}
                    autoComplete="off"
                    className="w-full h-[30px]"
                    placeholder={`Tổng số tiền gửi ${formatNumber(item?.max)}`}
                  />
                </div>
                <div className="tx_inputbox_r !w-[20%]">
                  <div className="inputbox_r_r !w-full !text-right">
                    <span className="fzmmm fcc !text-[14px]">USDT</span>
                  </div>
                </div>
              </div>
              <h5 className="mt-[10px] text-[16px]">
                Tổng doanh thu ngày:{" "}
                <b className="text-[18px]">{formatNumber(0, "0,0.000000")}</b>{" "}
                USDT
              </h5>
              <div className="flex  justify-between items-center gap-2 mt-[5px]">
                <div className="flex flex-col items-center">
                  <h4 className="text-[20px] font-bold text-center text-[#78C9EB]">
                    {formatNumber(item?.max_profit, "0,0.[000]")}% [USDT]
                  </h4>
                </div>
                <div className="flex flex-col items-center">
                  <h4 className="text-[20px] font-bold text-center text-[#78C9EB]">
                    {formatNumber(item?.date)}(Ngày)
                  </h4>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="sbui-btn  btn-fund-add"
              onClick={() => navigate(`/board/fund/detail/${item?.id}`)}
            >
              {i18next.t("Mua")}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
