import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import { useQueryClient } from "@tanstack/react-query";

const ContractAccount = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore: { tokens },
  } = useStores();

  useEffect(() => {
    queryClient.invalidateQueries(["get_profile"]);
  }, []);

  return (
    <div className="container spot-account pt-[50px] p-[10px]">
      <HeaderTop title="Tài khoản hợp đồng" />
      <div className="p-[20px]">
        {tokens?.map((item) => (
          <div className="mu-card" key={item?.id}>
            <div className="mycard mu-card-title-container">
              <div className="mu-card-title">{item?.name}</div>
              <div className="mu-card-sub-title">
                ≈{formatNumber(item?.convert_amount, "0,0.00")} USDT
              </div>
            </div>
            <div className="mu-card-actions">
              <div className="flex w-full">
                <div className="flex-1 text-white">
                  <i className="currency_title text-white">Khả dụng</i>
                  <br />
                  {formatNumber(item?.amount, "0,0.0000")}
                </div>
                <div className="flex-1 text-white">
                  <i className="currency_title text-white">Đóng băng</i>
                  <br />
                  {formatNumber(item?.pending_amount, "0,0.0000")}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default ContractAccount;
