import React, { useEffect, useState } from "react";
import { get, omit } from "lodash";
import moment from "moment";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useStores } from "_common/hooks";
import { useForm } from "react-hook-form";

import { observer } from "mobx-react";
import { NotificationManager } from "react-notifications";
import { ProfileApi } from "states/api";
import UploadFile from "_common/component/UploadFile";
import i18next from "i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const schema = yup
  .object({
    phone: yup.string().required(i18next.t("verify_require")),
  })
  .required();

const KycForm = observer(() => {
  const navigate = useNavigate();
  const {
    authStore: { user },
  } = useStores();
  const queryClient = useQueryClient();
  const [front_images, setFrontImage] = useState();
  const [back_images, setBackImage] = useState();

  const { mutate, isLoading } = useMutation(
    (variables) => ProfileApi.update({ params: variables }),
    {
      onSuccess: (res) => {
        navigate("/member");
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSave = (values) => {
    if (isLoading) return null;
    if (!front_images)
      return NotificationManager.warning(
        "Vui lòng tải lên file CMND/CCCD mặt trước"
      );
    if (!back_images)
      return NotificationManager.warning(
        "Vui lòng tải lên file CMND/CCCD mặt sau"
      );
    trigger();
    const formValue = getValues();
    const isValid = schema.isValidSync(formValue);
    if (isValid) {
      mutate({
        ...formValue,
        front_image: front_images,
        back_image: back_images,
      });
    }
    return null;
  };

  const onReceiveFrontImages = (images) => {
    setFrontImage(images[0]?.url);
  };

  const onReceiveBackImages = (images) => {
    setBackImage(images[0]?.url);
  };

  return (
    <form onSubmit={handleSubmit(onSave)} className=" py-[5px] px-[15px]">
      <div className="imgbox mt-0">
        <div className="imgbox_4 !border-[0] !m-[0]">
          <div className="imgbox_5">
            <span className="fzmmm fe6im f12">Số điện thoại di động</span>
          </div>
          <div className="zz_inputbox ">
            <input
              type="text"
              placeholder="Vui lòng nhập số điện thoại"
              className="zz_input !text-[#e6e6e6] !bg-[transparent]"
              {...register("phone")}
            />
          </div>
          <div className="imgbox_5">
            <span className="fzmmm fe6im f12">Xác thực</span>
          </div>
          <div className="zz_inputbox  flex items-center">
            <select
              placeholder={i18next.t("type_kyc")}
              className="inputbox-select"
            >
              <option value="cmnd">{i18next.t("id_card")}</option>
              <option value="passport">{i18next.t("passport")}</option>
              <option value="driver_license">{i18next.t("license")}</option>
            </select>
            <FontAwesomeIcon icon={faChevronDown} color="white" />
          </div>
          <div className="imgbox_5">
            <span className="fzmmm fe6im f12">Tải lên tài liệu tích cực</span>
          </div>
          <div className="imgbox_6  rounded-[10px]">
            <UploadFile
              onReceiveImages={onReceiveFrontImages}
              image={front_images}
              folder="kyc"
            />
          </div>
          <div className="imgbox_5">
            <span className="fzmmm fe6im f12">Tải lên chứng chỉ</span>
          </div>
          <div className="imgbox_6  rounded-[10px]">
            <UploadFile
              onReceiveImages={onReceiveBackImages}
              image={back_images}
              folder="kyc"
            />
          </div>
          <div className="imgbox_8">
            <div className="imgbox_10 !bg-[#3db485]" id="sumbtn">
              <button type="submit">
                <span>
                  {user?.is_verify
                    ? "Đánh giá chứng nhận thành công"
                    : i18next.t("send")}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});

export default KycForm;
