import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import LeftNav from "./LeftNav";

const UserPage = observer(() => (
  <main className="css-1wr4jig">
    <main className="css-xry4yv !flex-row">
      <LeftNav />
      <div className="css-160vccy">
        <div className="css-146q23">
          <div className="css-1e6doj4">
            <div className="css-6vt7sa">th</div>
            <div className="css-1sgz1lk">
              <div className="css-ize0sl">
                <div className="css-1124n14">than***@gmail.com</div>
                <div className="css-1uoge8i">
                  <div className="css-180eiyx">
                    <div className="css-1ap5wc6">người sử dụngID:</div>
                    7012687
                  </div>
                </div>
                <div className="css-lzd0h4">
                  <div className="css-bhso1m">đã xác minh</div>
                </div>
              </div>
              <div className="css-1ry7rnu">
                <div className="css-9cwl6c">
                  Thời gian đăng nhập cuối cùng 2023-09-10 04:05:24
                </div>
                <div className="css-vurnku">IP: 172.71.215.104</div>
              </div>
            </div>
          </div>
        </div>
        <div className="css-jlbk6n">
          <div className="css-kvkii2">
            <div className="css-1p01izn">
              <div className="css-1hythwr">
                <div className="css-1hz1mz6">
                  <div className="css-5x6ly7">
                    <div className="css-5x6ly7">
                      <span className="css-181kvgz !bg-[transparent]">
                        USDT Chi tiết số dư tài khoản
                      </span>
                    </div>
                  </div>
                </div>
                <div className="css-vurnku">
                  <div className="css-gnqbje">
                    <div className="css-ysetcg">
                      <div className="css-ct0qa6">
                        <div className="css-1bliacb">
                          <div className="css-vurnku">
                            <div className="css-1f978ju">
                              <div className="css-ize0sl">
                                <span className="css-1kbdyxh">
                                  USDT Số dư tài khoản
                                  <Link to="/user" className="text-[#00b897]">
                                    (Đi đến ví để xem tổng số dư tài sản)
                                  </Link>
                                </span>
                              </div>
                              <div className="css-n5mzgu">
                                <div className="css-off8uh">
                                  <div className="css-1t9tl2o">
                                    1360407.1339
                                  </div>
                                  <div className="w-[4px]" />
                                  <div className="css-vurnku">USDT</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="css-omwf4y">
            <div className="css-vt77s9">
              <div className="css-1hythwr">
                <div className="css-hwv82q">
                  <div className="css-5x6ly7">
                    <div className="css-5x6ly7">
                      <Link to="/Index/gglist" className="css-181kvgz">
                        Trung tâm thông báo
                      </Link>
                    </div>
                    <div className="css-10nf7hq">
                      <Link to="/Index/gglist" className="css-1s6nhe2">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="css-155meta"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="css-65w75">
                  <Link to="/Index/gginfo?id=63" className="css-1wuyyej">
                    <div className="LinesEllipsis  ">
                      Remitano bảo trì !<wbr />
                    </div>
                    <div className="css-1joqi3u">2023-06-17 14:16:43</div>
                  </Link>
                  <Link to="/Index/gginfo?id=61" className="css-1wuyyej">
                    <div className="LinesEllipsis  ">
                      Remitano Niêm yết 3 Cặp Copy Trading Hợp đồng Tương lai
                      Mới, Bao gồm ARBVND
                      <wbr />
                    </div>
                    <div className="css-1joqi3u">2023-06-14 21:18:24</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="css-ei3nni">
            <div className="css-1p01izn">
              <div className="css-1hythwr">
                <div className="css-hwv82q">
                  <div className="css-5x6ly7">
                    <div className="css-5x6ly7">
                      <Link to="/trade/order" className="css-181kvgz">
                        Được giao phó bởi giao dịch tiền tệ
                      </Link>
                    </div>
                    <div className="css-10nf7hq">
                      <Link to="/trade/order" className="css-1s6nhe2">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="css-155meta"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="css-1dcd6pv">
                  <div className="css-1h690ep">
                    <div className="css-phax11">
                      <div className="css-9b6x94">Giao dịch</div>
                      <div className="css-9b6x94">Thành Công</div>
                    </div>
                    <div className="css-phax11">
                      <div className="css-9b6x94">Loại hình / Hướng đi</div>
                      <div className="css-9b6x94">Điều kiện kích hoạt</div>
                    </div>
                    <div className="css-phax11">
                      <div className="css-9b6x94">Giới hạn giá</div>
                      <div className="css-9b6x94">số lượng</div>
                    </div>
                    <div className="css-phax11">
                      <div className="css-9b6x94">Doanh số</div>
                      <div className="css-9b6x94">Hoàn chỉnh</div>
                    </div>
                    <div className="css-1ngrbny">
                      <div className="css-9b6x94">Vận hành</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="css-ei3nni">
            <div className="css-1p01izn">
              <div className="css-1hythwr">
                <div className="css-hwv82q">
                  <div className="css-5x6ly7">
                    <div className="css-5x6ly7">
                      <Link to="/contract/contractjc" className="css-181kvgz">
                        Ủy ban hợp đồng thứ hai
                      </Link>
                    </div>
                    <div className="css-10nf7hq">
                      <Link to="/contract/contractjc" className="css-1s6nhe2">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="css-155meta"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="css-1dcd6pv">
                  <div className="css-1h690ep">
                    <div className="css-phax11 w-[25%]">
                      <div className="css-9b6x94">Giao dịch</div>
                      <div className="css-9b6x94">Thiết lập thời gian</div>
                    </div>
                    <div className="css-phax11 w-[25%]">
                      <div className="css-9b6x94">Loại hình / Hướng đi</div>
                      <div className="css-9b6x94">Điều kiện kích hoạt</div>
                    </div>
                    <div className="css-phax11 w-[25%]">
                      <div className="css-9b6x94">Đơn giá kho</div>
                      <div className="css-9b6x94">Đã xây dựng -in số tiền</div>
                    </div>
                    <div className="css-phax11 w-[25%]">
                      <div className="css-9b6x94">Thành Công</div>
                      <div className="css-9b6x94">Hoàn chỉnh</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </main>
));

export default UserPage;
