import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

import Banner from "./Banner";
import Notice from "./Notice";
import News from "./News";
import MarketPrice from "./MarketPrice";
import Feature1 from "./Feature1";
import JoinTeam from "./JoinTeam";

const DesktopHome = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { banners, top_coin, games, authenticated },
  } = useStores();

  return (
    <main className="css-1wr4jig">
      <Banner />
      <div>
        <div
          className="css-1t9l9dt"
          style={{ width: "100%", background: "#f6f7f9", padding: "55px 24px" }}
        >
          <div className="css-8hstpq" style={{ height: "220px" }}>
            <p className="notice-btn ">Thông báo:</p>
            <div className="box box_top">
              <Notice />
            </div>
          </div>
        </div>
      </div>
      <div className="css-194m5n4" style={{ paddingTop: 30 }}>
        <MarketPrice />
        <Feature1 />
      </div>
      <JoinTeam />
    </main>
  );
});

export default DesktopHome;
