import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useMediaQuery } from "_common/component/MediaQueryProvider";
import MobileHome from "./MobileHome";
import DesktopHome from "./DesktopHome";

const HomeUnAuth = observer(() => {
  const navigate = useNavigate();
  const { isMobile } = useMediaQuery();
  if (isMobile) return <MobileHome />;

  return <DesktopHome />;
});

export default HomeUnAuth;
