import React from "react";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import RegisterMobile from "./RegisterMobile";
import RegisterDesktop from "./RegisterDesktop";

function RegisterPage() {
  const { isMobile } = useMediaQuery();
  if (isMobile) return <RegisterMobile />;

  return <RegisterDesktop />;
}

export default RegisterPage;
