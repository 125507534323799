import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import SpotTradeMobilePage from "./Mobile/SpotTradeMobile";
import SpotTradeDesktopPage from "./Desktop/SpotTradeDesktop";

const SpotTradePage = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <SpotTradeMobilePage />;
  return <SpotTradeDesktopPage />;
});

export default SpotTradePage;
