import classNames from "classnames";
import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { useStores } from "_common/hooks";
import icon1 from "assets/img/icon/index1.png";
import icon2 from "assets/img/icon/index2.png";
import icon3 from "assets/img/icon/index3.png";
import icon4 from "assets/img/icon/index4.png";
import icon5 from "assets/img/icon/index5.png";
import i18next from "i18next";

export default function MobileFooter() {
  const location = useLocation();
  const pathName = location?.pathname;
  const {
    authStore: { general },
  } = useStores();

  return (
    <div className="footer ">
      <Link
        to="/"
        className={classNames("footer_item", {
          active: pathName === "/",
        })}
      >
        <img src={icon1} alt="" />
        <h5>{i18next.t("page_home")}</h5>
      </Link>
      <Link
        to="/board/spot"
        className={classNames("footer_item", {
          active: pathName === "/board/spot",
        })}
      >
        <img src={icon2} alt="" />
        <h5>{i18next.t("spot")}</h5>
      </Link>
      <Link
        to="/contract"
        className={classNames("footer_item", {
          active: pathName === "/contract",
        })}
      >
        <img src={icon3} alt="" />
        <h5>{i18next.t("contract")}</h5>
      </Link>
      <Link
        to="/board/fund"
        className={classNames("footer_item", {
          active: pathName === "/board/fund",
        })}
      >
        <img src={icon4} alt="" />
        <h5>{i18next.t("Staking")}</h5>
      </Link>
      <Link
        to="/board/wallet"
        className={classNames("footer_item", {
          active: pathName === "/board/wallet",
        })}
      >
        <img src={icon5} alt="" />
        <h5>{i18next.t("asset")}</h5>
      </Link>
    </div>
  );
}
