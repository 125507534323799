import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";
import { formatNumber } from "_common/utils/formatValue";
import { NotificationManager } from "react-notifications";
import { GameApi } from "states/api/game";
import { get } from "lodash";
import ModalBet from "./ModalBet";

const buttons = [1, 5, 10, 20];
const levers = [5, 10, 15, 20, 100];

export default function MarketPrice({ data_price, game_id }) {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, betGame } = useNotify();
  const {
    authStore: { user, display_balance, loading, setLoading, general },
  } = useStores();

  const [game_type, setGameType] = useState("sell");
  const [openModal, setOpenModal] = useState(false);
  const [amount, setAmount] = useState(buttons[0]);
  const [price_lose, setPriceLose] = useState(0);
  const [price_profit, setPriceProfit] = useState(0);
  const [level, setLevel] = useState(levers[0]);

  const handleChangeInput = (event) => {
    setAmount(parseInt(event?.target?.value, 10));
  };

  const handleChangeInputLose = (event) => {
    setPriceLose(parseInt(event?.target?.value, 10));
  };

  const handleChangeInputProfit = (event) => {
    setPriceProfit(parseInt(event?.target?.value, 10));
  };

  const amountBet = useMemo(() => {
    if (!data_price) return 0;
    const lastPrice = Number(data_price?.lastPrice);

    return (lastPrice / level) * amount;
  }, [data_price, level, amount]);

  useEffect(() => {
    if (!data_price) return;
    const lastPrice = Number(data_price?.lastPrice);
    const beta = 100 / level / 100;
    const priceAlpha = lastPrice * beta;

    let resultLose = lastPrice + priceAlpha;
    let resultProfit = lastPrice - priceAlpha;
    if (game_type === "buy") {
      resultLose = lastPrice - priceAlpha;
      resultProfit = lastPrice + priceAlpha;
    }

    setPriceLose(resultLose);
    setPriceProfit(resultProfit);
  }, [data_price, game_type, level, amount]);

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      GameApi.betGame({
        params: {
          game_id,
          ...variables,
        },
      }),
    {
      onSuccess: (res, variables) => {
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["game-feature", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
        setOpenModal(false);
        pushNotify({
          type: "betGame",
          status: "new_bet",
        });
      },
      onError: (error) => {
        setLoading(false);
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleSubmit = async () => {
    setLoading(true);
    if (loading) return null;
    if (!amountBet)
      return NotificationManager.error(i18next.t("enter_large_amount_msg"));
    if (!game_type)
      return NotificationManager.error(i18next.t("select_game_type_msg"));
    if (!game_id)
      return NotificationManager.error(i18next.t("select_game_type_msg"));

    onSubmit({
      amount,
      profit: amount,
      type: "feature",
      bet_game: game_type,
      price: data_price?.lastPrice,
    });
    return null;
  };

  return (
    <>
      <h5>{i18next.t("trading_direction")}</h5>
      <div className="diver" />
      <div className="group-action">
        <button
          type="button"
          className={classNames("btn btn-sell ", {
            active: game_type === "sell",
          })}
          onClick={() => setGameType("sell")}
        >
          {i18next.t("sell")}
        </button>
        <button
          type="button"
          className={classNames("btn btn-buy ", {
            active: game_type === "buy",
          })}
          onClick={() => setGameType("buy")}
        >
          {i18next.t("buy")}
        </button>
      </div>
      <h5>{i18next.t("number_of_trading_lots")}</h5>
      <div className="diver" />
      <div className="quantity">
        {buttons.map((item) => (
          <button
            key={`num-${item.toString()}`}
            type="button"
            className={classNames("btn", { active: amount === item })}
            onClick={() => setAmount(item)}
          >
            {item}
          </button>
        ))}
      </div>
      <div className="qty-input">
        <button
          type="button"
          className="btn btn-add"
          onClick={() => setAmount(amount > 2 && amount - 1)}
        >
          -
        </button>
        <input
          placeholder="0.0"
          type="number"
          inputMode="decimal"
          className="sbui-input sbui-input--large"
          min={0}
          step="1"
          value={amount}
          onChange={handleChangeInput}
        />
        <button
          type="button"
          className="btn btn-add"
          onClick={() => setAmount(amount + 1)}
        >
          +
        </button>
      </div>
      <div className="diver" />
      <div className="info">
        <span>{i18next.t("take_the_deposit")}</span>
        <span>{formatNumber(amountBet, "0,0.[00000]")}</span>
      </div>
      <div className="info">
        <span>{i18next.t("transaction_fee")}</span>
        <span>{formatNumber(0, "0,0.[00000]")}</span>
      </div>
      <div className="info mb-5">
        <span>{i18next.t("balance_available")}</span>
        <span>{formatNumber(user?.balance, "0,0.[00000]")}</span>
      </div>
      <div className="diver" />
      <div className="qty-info">
        <span>{i18next.t("leverage_multiples")}</span>
        <span className="show">{level}</span>
      </div>
      <div className="diver" />
      <div className="quantity">
        {levers.map((item) => (
          <button
            key={`num-${item.toString()}`}
            type="button"
            className={classNames("btn", { active: level === item })}
            onClick={() => setLevel(item)}
          >
            {item}
          </button>
        ))}
      </div>
      <div className="diver" />
      <div className="qty-info">
        <span>{i18next.t("stop_loss_price")}</span>
        <div className="qty-input">
          <button
            type="button"
            className="btn btn-add"
            onClick={() => setPriceLose(price_lose > 1000 && price_lose - 1000)}
          >
            -
          </button>
          <input
            placeholder="0.0"
            type="number"
            inputMode="decimal"
            className="sbui-input sbui-input--large"
            min={0}
            step="1"
            value={price_lose}
            onChange={handleChangeInputLose}
          />
          <button
            type="button"
            className="btn btn-add"
            onClick={() => setPriceLose(price_lose + 1000)}
          >
            +
          </button>
        </div>
      </div>

      <div className="diver" />
      <div className="qty-info">
        <span>{i18next.t("profit_taking_price")}</span>
        <div className="qty-input">
          <button
            type="button"
            className="btn btn-add"
            onClick={() =>
              setPriceProfit(price_profit > 1000 && price_profit - 1000)
            }
          >
            -
          </button>
          <input
            placeholder="0.0"
            type="number"
            inputMode="decimal"
            className="sbui-input sbui-input--large"
            min={0}
            step="1"
            value={price_profit}
            onChange={handleChangeInputProfit}
          />
          <button
            type="button"
            className="btn btn-add"
            onClick={() => setPriceProfit(price_profit + 1000)}
          >
            +
          </button>
        </div>
      </div>
      <div className="feature-footer">
        <button
          type="button"
          onClick={() => setOpenModal(true)}
          className={classNames("btn-feature", {
            sell: game_type === "sell",
            buy: game_type === "buy",
          })}
        >
          {game_type === "sell" ? i18next.t("sell") : i18next.t("buy")}
        </button>
        {openModal && (
          <ModalBet
            open={openModal}
            onClose={() => setOpenModal(false)}
            handleSubmit={handleSubmit}
            data={{
              game_id,
              game_type,
              amount,
            }}
          />
        )}
      </div>
    </>
  );
}
