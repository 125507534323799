import React, { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GameApi } from "states/api/game";
import { NotificationManager } from "react-notifications";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";
import { Portal } from "react-portal";

import { PriceApi } from "states/api/price";

const ModalBet = observer(({ open, onClose, data }) => {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, dataGame, betGame } = useNotify();
  const {
    authStore: { user, loading, setLoading, general },
  } = useStores();

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      GameApi.betGame({
        params: {
          game_id: data.game_id,
          ...variables,
        },
      }),
    {
      onSuccess: (res, variables) => {
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["game-spot", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );

        betGame({
          user_id: user?.id,
          time: variables?.type,
          game_id: res?.data?.id,
          ...variables,
        });
        pushNotify({
          type: "betGame",
          status: "new_bet",
        });
        onClose();
      },
      onError: (error) => {
        setLoading(false);
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (loading) return null;
      if (!data?.amount) throw i18next.t("enter_large_amount_msg");
      if (!data?.game_type) throw i18next.t("select_game_type_msg");
      if (!data?.type) throw i18next.t("select_game_type_msg");

      onSubmit({
        amount: data.amount,
        type: data?.type,
        bet_game: data?.game_type,
        price: dataGame?.lastPrice,
      });
      return null;
    } catch (error) {
      setLoading(false);
      return NotificationManager.error(error);
    }
  };

  if (!open) return <div />;

  return (
    <Portal>
      <div className="modal modal-bet">
        <div className="mask" />
        <div className="modal-content" style={{ width: "80%" }}>
          <div className="modal-header">
            <h5>{i18next.t("confirm_order")}</h5>
          </div>
          <div className="modal-body">
            <div className="info-table mb-2">
              <div className="item">
                <span>{i18next.t("opening_quantity")}</span>
                <span>{formatNumber(data?.amount, "0,0.[0000]")} USDT</span>
              </div>
              <div className="item">
                <span>{i18next.t("transaction_mode")}</span>
                <span>{data?.type} Sec</span>
              </div>
              <div className="item">
                <span>{i18next.t("profit_rate")}</span>
                <span>{data?.profit_rate}%</span>
              </div>
            </div>
            <div className="btn-group">
              <button
                type="button"
                className="btn-cancel"
                onClick={() => onClose()}
              >
                {i18next.t("cancel")}
              </button>
              <button
                type="button"
                className="btn-save"
                onClick={() => handleSubmit()}
              >
                {data?.game_type === "sell" && (
                  <span className="text-red">{i18next.t("fall")} </span>
                )}
                {data?.game_type === "buy" && (
                  <span className="text-green">{i18next.t("up")} </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
});

export default ModalBet;
