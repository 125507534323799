import React, { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GameApi } from "states/api/game";
import { NotificationManager } from "react-notifications";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";
import { Portal } from "react-portal";
import { gameTypeList } from "_common/constants/statusType";

const ModalBet = observer(({ open, onClose, type, game_id }) => {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, dataGame, betGame } = useNotify();
  const {
    authStore: { user, loading, setLoading, general },
  } = useStores();

  const [game_type, setGameType] = useState(30);
  const [amount, setAmount] = useState();

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      GameApi.betGame({
        params: {
          game_id,
          ...variables,
        },
      }),
    {
      onSuccess: (res, variables) => {
        setLoading(false);
        setAmount();
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["game-player", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );

        betGame({
          user_id: user?.id,
          time: variables?.type,
          game_id: res?.data?.id,
          ...variables,
        });
        pushNotify({
          type: "betGame",
          status: "new_bet",
        });
        onClose();
      },
      onError: (error) => {
        setLoading(false);
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleSubmit = async () => {
    if (loading) return null;
    if (!amount)
      return NotificationManager.error(i18next.t("enter_large_amount_msg"));
    if (!game_type)
      return NotificationManager.error(i18next.t("select_game_type_msg"));
    if (!game_id)
      return NotificationManager.error(i18next.t("select_game_type_msg"));
    setLoading(true);
    onSubmit({
      amount,
      type: game_type,
      bet_game: type,
      price: dataGame?.lastPrice,
    });
    return null;
  };

  if (!open) return <div />;

  return (
    <Portal>
      <div className="modal modal-bet" style={{ opacity: open ? 1 : 0 }}>
        <div className="mask" />
        <div className="modal-content !border-0 !rounded-none !top-[0] !bottom-[0] !translate-x-[-50%] !translate-y-[0]">
          <div className="modal-header !border-[#d6d6d9] ">
            <h1>
              Xác nhận giao dịch{" "}
              <b className="text-uppercase">{game_id?.split("usdt")?.[0]}</b>
              /USDT
            </h1>
            <span className="btn-close" onClick={onClose}>
              x
            </span>
          </div>
          <div className="modal-body">
            <p className="dong_order_p">Chu kỳ lựa chọn</p>
            <div className="list-bet">
              {gameTypeList.map((item) => (
                <div
                  key={item.time}
                  className={classNames("item", {
                    active: item.time === game_type,
                  })}
                  onClick={() => setGameType(item.time)}
                >
                  <p>{item.text}</p>
                  <p>{formatNumber(item.profit, "0,0.0")}%</p>
                </div>
              ))}
            </div>
            <p className="dong_order_p">Số tiền tùy chọn</p>
            <div className="list-bet">
              {[100, 1000, 10000, 100000].map((item) => (
                <div
                  key={item}
                  className={classNames("item", {
                    active: amount === item,
                  })}
                  onClick={() => setAmount(item)}
                >
                  <p>{formatNumber(item)}</p>
                  <p>USDT</p>
                </div>
              ))}
            </div>
            <p className="dong_order_p">Số lượng tùy chỉnh</p>
            <div className="info-table">
              <input
                type="number"
                className="input-number"
                inputMode="decimal"
                placeholder={`${i18next.t("minimum")} 5 ${i18next.t(
                  "start_buying"
                )}`}
                value={amount}
                onChange={(e) => setAmount(e?.target?.value)}
              />
            </div>
            <p className="dong_order_p !mt-[20px]">
              Số dư tài khoản：{formatNumber(user?.balance, "0,0.[0000]")}
            </p>
            <div className="w-full h-[50px] !border-[#d6d6d9] !border-t-2 pt-[10px] mt-[10px] flex">
              <div className="w-[25%] h-[50px] text-[14px] text-[#2c3a4b] flex flex-col items-center">
                <div>Giao dịch</div>
                <div className="text-uppercase">
                  {game_id?.split("usdt")?.[0]}/USDT
                </div>
              </div>
              <div className="w-[25%] h-[50px] text-[14px] text-[#2c3a4b] flex flex-col items-center">
                <div>Hướng đi</div>
                <div
                  className={`text-uppercase ${
                    type === "buy" ? "text-[#0ecb81]" : "text-[#f5465c]"
                  }`}
                >
                  {type}
                </div>
              </div>
              <div className="w-[25%] h-[50px] text-[14px] text-[#2c3a4b] flex flex-col items-center">
                <div>Giá hiện tại</div>
                <div className="text-uppercase">
                  {formatNumber(dataGame?.lastPrice, "0,0.[0000]")}
                </div>
              </div>
              <div className="w-[25%] h-[50px] text-[14px] text-[#2c3a4b] flex flex-col items-center">
                <div>Số lượng</div>
                <div className="text-uppercase">{amount}</div>
              </div>
            </div>
            <button
              type="button"
              className="btn-confirm"
              onClick={() => handleSubmit()}
            >
              Đặt lệnh mua
            </button>
          </div>
        </div>
      </div>
    </Portal>
  );
});

export default ModalBet;
